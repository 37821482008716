@font-face {
  font-family: "font-title";
  src: url(../fonts/Montserrat-Light.ttf);
}
@font-face {
  font-family: "font-text";
  src: url(../fonts/Raleway-Regular.ttf);
}
* {
  margin: 0;
  padding: 0;
  list-style-type: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body {
  overflow-x: hidden;
  min-height: 100vh;
  color: #ebebeb;
}
@media screen and (max-width: 1035px) {
  body {
    min-height: auto;
  }
}

.navlink {
  color: #ebebeb;
  text-decoration: none;
}
.navlink:visited {
  color: #ebebeb;
}

.navlink2:visited {
  color: #ebebeb;
}

li {
  color: #ebebeb;
}

#navigation {
  width: 100vw;
  height: 20vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #002f4a;
  color: #ebebeb;
  font-family: "font-title", sans-serif;
}
#navigation #navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 40%;
  z-index: 1;
}
#navigation #navbar #dropdown {
  position: absolute;
  background-color: #002f4a;
  color: #ebebeb;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 1em black;
          box-shadow: 0 0 1em black;
  z-index: 0;
  width: 100%;
  top: 15vh;
}
#navigation #navbar #dropdown .navlink2 {
  width: 100%;
  height: auto;
  text-align: center;
  text-decoration: none;
  font-size: 1.5vh;
  margin-bottom: 1vh;
  margin-top: 1.5vh;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
#navigation #navbar #dropdown .navlink2:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  font-weight: 900;
}
@media screen and (max-width: 1035px) {
  #navigation #navbar #dropdown .navlink2 {
    display: none;
  }
}
#navigation #navbar .navlink {
  margin: 5px;
  width: 7vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1vw;
  letter-spacing: 3px;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
#navigation #navbar .navlink:hover {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
  background-color: rgb(11, 155, 212);
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  -webkit-box-shadow: 0 0 2em black;
          box-shadow: 0 0 2em black;
}
#navigation #navbar .navlink::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  height: 2px;
  background-color: #ebebeb;
  top: 60%;
  display: block;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
#navigation #navbar .navlink:hover::after {
  width: 68%;
}
@media screen and (max-width: 1035px) {
  #navigation #navbar .navlink {
    font-size: 1vh;
    letter-spacing: 0;
    width: 10vw;
  }
}
@media screen and (max-width: 1035px) {
  #navigation #navbar {
    width: 60%;
  }
}
#navigation #logo-container {
  width: 20%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-image: url("../img/logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
@media screen and (max-width: 1035px) {
  #navigation #logo-container {
    display: none;
  }
}
#navigation #reseauxNav {
  width: 40%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
#navigation #reseauxNav li {
  width: 8vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media screen and (max-width: 1035px) {
  #navigation #reseauxNav li {
    margin: 5px;
  }
}
#navigation #reseauxNav li svg {
  height: 3vh;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
@media screen and (max-width: 1035px) {
  #navigation #reseauxNav li svg {
    height: 2vh;
  }
}
#navigation #reseauxNav li svg:hover {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
  cursor: pointer;
}
#navigation .dropdown2 {
  position: absolute;
  background-color: rgb(11, 155, 212);
  color: #ebebeb;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 1em black;
          box-shadow: 0 0 1em black;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 10vh;
  left: 6vw;
  top: 0;
}
#navigation .dropdown2::-webkit-scrollbar {
  width: 8px;
  border-radius: 4px;
}
#navigation .dropdown2::-webkit-scrollbar-thumb {
  background-color: #ebebeb;
  border-radius: 4px;
}
#navigation .dropdown2::-webkit-scrollbar-track {
  background-color: #002f4a;
  border-radius: 4px;
}
#navigation .dropdown2 .navlink3 {
  width: 80%;
  text-align: center;
  text-decoration: none;
  font-size: 0.8vh;
}
#navigation .dropdown2 .navlink3 li {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin-top: 1vh;
  margin-bottom: 2vh;
}
#navigation .dropdown2 .navlink3 li:hover {
  background-color: 0;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  font-weight: 900;
}
#navigation .dropdown2 .navlink3:visited {
  color: #ebebeb;
}

#popup {
  height: 25vh;
  width: 20vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
  position: absolute;
  border-radius: 76% 24% 90% 10%/20% 85% 15% 80%;
  -webkit-box-shadow: 0 0 1em black;
          box-shadow: 0 0 1em black;
  background-color: rgb(11, 155, 212);
  position: fixed;
  top: 100%;
}
#popup h2 {
  font-family: "font-title", sans-serif;
  letter-spacing: 1px;
  font-size: 2.5vh;
}
#popup form div {
  height: 5vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#popup form div label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 2vw;
  margin-right: 5px;
  font-family: "font-text", sans-serif;
  font-size: 1.5vh;
}
#popup form div #email {
  margin: auto;
  height: 2vh;
  max-width: 8vw;
  margin-left: 10px;
}
#popup form div #mess {
  width: 12vw;
  max-width: 12vw;
  height: 6vh;
  max-height: 6vh;
  margin-left: 10px;
}
#popup form #button {
  width: 5vw;
  height: 3vh;
  background-color: rgba(0, 47, 74, 0.368627451);
  color: #ebebeb;
  border-radius: 25px;
  border: 1px solid rgba(0, 47, 74, 0.368627451);
  margin-top: 10%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  font-family: "font-title", sans-serif;
  font-size: 1.5vh;
}
#popup form #button:hover {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
  cursor: pointer;
  background-color: #002f4a;
  -webkit-box-shadow: 0 0 1em black;
          box-shadow: 0 0 1em black;
}
#popup .iconX {
  position: absolute;
  border: 2px solid rgba(0, 47, 74, 0.368627451);
  border-radius: 100%;
  width: 2vw;
  height: 4vh;
  padding: 5px;
  left: 77%;
  top: -5%;
  color: rgba(0, 47, 74, 0.368627451);
  background-color: #ebebeb;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}
#popup .iconX:hover {
  color: #002f4a;
  border-color: #002f4a;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  cursor: pointer;
  -webkit-box-shadow: 0 0 1em black;
          box-shadow: 0 0 1em black;
}
@media screen and (max-width: 1035px) {
  #popup {
    display: none;
    right: 10000px;
  }
}

.project {
  position: relative;
  overflow: hidden;
  height: 100vh;
}
.project .rightArrow {
  position: absolute;
  top: 53%;
  left: 90%;
  font-size: 5vh;
  color: rgba(0, 47, 74, 0.368627451);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.project .rightArrow:hover {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
  color: #002f4a;
}
.project .leftArrow {
  position: absolute;
  top: 53%;
  left: 10%;
  font-size: 5vh;
  color: rgba(0, 47, 74, 0.368627451);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.project .leftArrow:hover {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
  color: #002f4a;
}
.project .project-container {
  height: 50vh;
  width: 30vw;
  margin: auto;
  margin-top: 15vh;
  z-index: 0;
  border-radius: 20px;
  -webkit-box-shadow: 0 0 1em black;
          box-shadow: 0 0 1em black;
  -webkit-transition: 1s;
  transition: 1s;
}
.project .project-container:hover {
  cursor: pointer;
}
.project .project-container .circle1 {
  position: absolute;
  border: 5px solid rgba(0, 47, 74, 0.368627451);
  height: 500px;
  width: 500px;
  border-radius: 300%;
  top: 10%;
  left: 50%;
  z-index: -1;
}
.project .project-container .circle2 {
  position: absolute;
  background-color: rgba(0, 47, 74, 0.368627451);
  height: 300px;
  width: 300px;
  border-radius: 300%;
  top: 70%;
  left: 89%;
  z-index: -1;
}
.project .project-container .circle3 {
  position: absolute;
  background-color: rgba(11, 155, 212, 0.438);
  height: 200px;
  width: 200px;
  border-radius: 300%;
  top: 10%;
  left: 3%;
  z-index: -1;
}
.project .project-container .project-title {
  background-color: rgb(11, 155, 212);
  height: 5vh;
  width: 20vw;
  position: absolute;
  top: 32%;
  left: 40%;
  border-radius: 20px;
  -webkit-box-shadow: 0 0 1em black;
          box-shadow: 0 0 1em black;
  overflow: hidden;
}
.project .project-container .project-title .title-cache {
  height: 5vh;
  width: 10vw;
  background-color: #002f4a;
  position: absolute;
  z-index: 5;
  -webkit-box-shadow: 0 0 3em black;
          box-shadow: 0 0 3em black;
  -webkit-transform: skew(20deg);
          transform: skew(20deg);
}
.project .project-container .project-title h1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "font-title", sans-serif;
  margin-top: 5px;
  font-size: 3vh;
}
@media screen and (max-width: 1035px) {
  .project .project-container .project-title h1 {
    font-size: 1.5vh;
  }
}
@media screen and (max-width: 1035px) {
  .project .project-container .project-title {
    width: 35vw;
    left: 33%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-bottom: 1vh;
  }
}
.project .project-container .project-languages {
  background-color: rgb(11, 155, 212);
  height: 30vh;
  width: 15vw;
  position: absolute;
  border-radius: 20px;
  -webkit-box-shadow: 0 0 1em black;
          box-shadow: 0 0 1em black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.project .project-container .project-languages ul li {
  font-family: "font-text", sans-serif;
  margin: 8px;
  font-size: 3vh;
}
@media screen and (max-width: 1035px) {
  .project .project-container .project-languages ul li {
    font-size: 1.5vh;
  }
}
@media screen and (max-width: 1035px) {
  .project .project-container .project-languages {
    width: 25vw;
    margin-left: 35%;
    margin-top: 35%;
    height: 20vh;
  }
}
.project .project-container .project-description {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #002f4a;
  height: 40vh;
  width: 17vw;
  position: absolute;
  border-radius: 20px;
  -webkit-box-shadow: 0 0 1em black;
          box-shadow: 0 0 1em black;
}
.project .project-container .project-description p {
  font-family: "font-text", sans-serif;
  margin: 8px;
  margin-left: 12px;
  font-size: 2vh;
}
@media screen and (max-width: 1035px) {
  .project .project-container .project-description p {
    font-size: 1vh;
  }
}
@media screen and (max-width: 1035px) {
  .project .project-container .project-description {
    height: 20vh;
    width: 25vw;
  }
}
.project .project-container #code-box {
  display: none;
}
.project .project-container #site-box {
  display: none;
}
@media screen and (max-width: 1035px) {
  .project .project-container {
    width: 65vw;
  }
}
.project .hoverProject {
  height: 50vh;
  width: 50vw;
  margin: auto;
  margin-top: 15vh;
  z-index: 0;
  border-radius: 20px;
  -webkit-box-shadow: 0 0 2em black;
          box-shadow: 0 0 2em black;
  -webkit-transition: 1s ease;
  transition: 1s ease;
}
.project .hoverProject:hover {
  cursor: pointer;
}
.project .hoverProject .project-title {
  display: none;
}
.project .hoverProject .project-languages {
  display: none;
}
.project .hoverProject .project-description {
  display: none;
}
.project .hoverProject #code-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #002f4a;
  width: 10vw;
  height: 8vh;
  border-radius: 10px;
  position: absolute;
  top: 40%;
  left: 35%;
  -webkit-box-shadow: 0 0 1em black;
          box-shadow: 0 0 1em black;
  font-family: "font-title", sans-serif;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 1.5vh;
}
.project .hoverProject #code-box:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  background-color: rgb(11, 155, 212);
}
.project .hoverProject #code-box h2 {
  margin-right: 0.5vw;
}
.project .hoverProject #code-box h2::before {
  color: #ebebeb;
}
@media screen and (max-width: 1035px) {
  .project .hoverProject #code-box {
    width: 25vw;
    left: 20%;
    font-size: 1vh;
  }
}
.project .hoverProject #site-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #002f4a;
  width: 10vw;
  height: 8vh;
  border-radius: 10px;
  position: absolute;
  top: 40%;
  left: 55%;
  -webkit-box-shadow: 0 0 1em black;
          box-shadow: 0 0 1em black;
  font-family: "font-title", sans-serif;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 1.5vh;
}
.project .hoverProject #site-box:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  background-color: rgb(11, 155, 212);
}
.project .hoverProject #site-box h2 {
  margin-right: 0.5vw;
}
@media screen and (max-width: 1035px) {
  .project .hoverProject #site-box {
    width: 25vw;
    font-size: 1vh;
  }
}
.project .hoverProject .circle1 {
  position: absolute;
  border: 5px solid rgba(0, 47, 74, 0.368627451);
  height: 500px;
  width: 500px;
  border-radius: 300%;
  top: 10%;
  left: 50%;
  z-index: -1;
}
.project .hoverProject .circle2 {
  position: absolute;
  background-color: rgba(0, 47, 74, 0.368627451);
  height: 300px;
  width: 300px;
  border-radius: 300%;
  top: 70%;
  left: 89%;
  z-index: -1;
}
.project .hoverProject .circle3 {
  position: absolute;
  background-color: rgba(11, 155, 212, 0.438);
  height: 200px;
  width: 200px;
  border-radius: 300%;
  top: 10%;
  left: 3%;
  z-index: -1;
}
@media screen and (max-width: 1035px) {
  .project .hoverProject {
    width: 80vw;
  }
}
.project #project1-pic {
  background-image: url("../img/Projet1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.project #project2-pic {
  background-image: url("../img/Projet2.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.project #project3-pic {
  background-image: url("../img/Projet3.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.project #project4-pic {
  background-image: url("../img/Projet4.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.project #project5-pic {
  background-image: url("../img/Projet5.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.project #project6-pic {
  background-image: url("../img/Projet6.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.project #project7-pic {
  background-image: url("../img/Projet7.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.project #project8-pic {
  background-image: url("../img/Projet8.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.project #project9-pic {
  background-image: url("../img/Projet9.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.project #project10-pic {
  background-image: url("../img/Projet10.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.project #project11-pic {
  background-image: url("../img/Projet11.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.project #project12-pic {
  background-image: url("../img/Projet12.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.project #project13-pic {
  background-image: url("../img/Projet13.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.project #project14-pic {
  background-image: url("../img/Projet14.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.project #project15-pic {
  background-image: url("../img/Projet15.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.project #project16-pic {
  background-image: url("../img/Projet16.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.project #project17-pic {
  background-image: url("../img/Projet17.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.project #project18-pic {
  background-image: url("../img/Projet18.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.project #project19-pic {
  background-image: url("../img/Projet19.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.project #project20-pic {
  background-image: url("../img/Projet20.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.project #project21-pic {
  background-image: url("../img/Projet21.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.project #project22-pic {
  background-image: url("../img/Projet22.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.project #project23-pic {
  background-image: url("../img/Projet23.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.project #project24-pic {
  background-image: url("../img/Projet24.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.project #project25-pic {
  background-image: url("../img/Projet25.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.project #project26-pic {
  background-image: url("../img/Projet26.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.project #project27-pic {
  background-image: url("../img/Projet27.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.project #project28-pic {
  background-image: url("../img/Projet28.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.project #project29-pic {
  background-image: url("../img/Projet29.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.project #project30-pic {
  background-image: url("../img/Projet30.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.project #project31-pic {
  background-image: url("../img/Projet31.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

#notFound {
  text-decoration: none;
  background-color: #ebebeb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100vh;
}
#notFound h1 {
  font-size: 5vh;
  margin: 30px;
  color: #002f4a;
}
#notFound h3 {
  color: #002f4a;
}

#home {
  overflow: hidden;
  height: 100vh;
}
#home #content-home {
  margin: auto;
  height: 50vh;
  width: 60vw;
  position: relative;
}
#home #content-home h3 {
  font-family: "font-text", sans-serif;
  background-color: rgb(11, 155, 212);
  position: absolute;
  padding: 3%;
  padding-right: 6%;
  max-width: 35vw;
  font-size: 3vh;
  border-radius: 76% 24% 90% 10%/20% 85% 15% 80%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  letter-spacing: 2px;
  -webkit-box-shadow: 0 0 1em black;
          box-shadow: 0 0 1em black;
}
@media screen and (max-width: 1035px) {
  #home #content-home h3 {
    max-width: 70vw;
    font-size: 2.5vh;
    padding-right: 3%;
  }
}
#home #content-home .photo_home {
  position: absolute;
  background-image: url("../img/me.png");
  height: 200px;
  width: 200px;
  margin-top: -13vh;
  margin-left: 6.5vw;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  background-size: contain;
}
@media screen and (max-width: 1335px) {
  #home #content-home .photo_home {
    display: none;
  }
}
#home #content-home h2 {
  font-family: "font-title", sans-serif;
  background-color: #002f4a;
  position: absolute;
  padding: 2.5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 2;
  font-size: 2.5vh;
  max-height: 8vh;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 1em black;
          box-shadow: 0 0 1em black;
}
@media screen and (max-width: 1035px) {
  #home #content-home h2 {
    margin-left: 5%;
  }
}
#home #content-home #first-text {
  padding: 3%;
  font-family: "font-text", sans-serif;
  background-color: rgb(11, 155, 212);
  position: absolute;
  max-width: 23vw;
  max-height: 25vh;
  font-size: 1.6vh;
  z-index: 0;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 1em black;
          box-shadow: 0 0 1em black;
}
@media screen and (max-width: 1035px) {
  #home #content-home #first-text {
    max-width: 50vw;
    font-size: 1vh;
  }
}
#home #content-home #second-text {
  padding: 3%;
  font-family: "font-text", sans-serif;
  background-color: #002f4a;
  position: absolute;
  max-width: 23vw;
  max-height: 35vh;
  font-size: 1.6vh;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 1em black;
          box-shadow: 0 0 1em black;
}
@media screen and (max-width: 1035px) {
  #home #content-home #second-text {
    max-width: 50vw;
    font-size: 1vh;
    margin-top: 5%;
  }
}
#home #content-home .circle1 {
  position: absolute;
  border: 5px solid rgba(0, 47, 74, 0.368627451);
  height: 500px;
  width: 500px;
  border-radius: 300%;
  top: 70%;
  left: -20%;
  z-index: -1;
}
#home #content-home .circle2 {
  position: absolute;
  background-color: rgba(0, 47, 74, 0.368627451);
  height: 300px;
  width: 300px;
  border-radius: 300%;
  top: -10%;
  left: 82%;
  z-index: -1;
}
#home #content-home .circle3 {
  position: absolute;
  background-color: rgba(11, 155, 212, 0.438);
  height: 200px;
  width: 200px;
  border-radius: 300%;
  top: 10%;
  left: -43%;
  z-index: -1;
}
#home #content-home .circle4 {
  position: absolute;
  border: 5px solid rgba(11, 155, 212, 0.438);
  height: 1000px;
  width: 1000px;
  border-radius: 300%;
  top: 70%;
  left: 100%;
  z-index: -1;
}
@media screen and (max-width: 1035px) {
  #home #content-home {
    width: 100vw;
  }
}
#home footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #002f4a;
  width: 50vw;
  height: 6vh;
  border-top-right-radius: 15px;
  font-family: "font-text", sans-serif;
  font-size: 1.5vh;
}
#home footer .navLi {
  margin: auto;
  text-decoration: none;
}
#home footer li {
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
#home footer li:hover {
  color: rgb(11, 155, 212);
  cursor: pointer;
}
#home footer li svg {
  text-align: center;
  margin-right: 6px;
}
@media screen and (max-width: 1035px) {
  #home footer {
    width: 100vw;
    border-top-right-radius: 0px;
    font-size: 0.7vh;
  }
}

#cv-global {
  max-width: 100vw;
  overflow-x: hidden;
}
#cv-global #cv-container {
  height: 100vh;
  margin-bottom: 300px;
}
#cv-global #cv-container #Cv .circle1 {
  position: absolute;
  border: 5px solid rgba(0, 47, 74, 0.368627451);
  height: 500px;
  width: 500px;
  border-radius: 300%;
  top: 0%;
  left: 85%;
  z-index: -1;
}
#cv-global #cv-container #Cv .circle2 {
  position: absolute;
  background-color: rgba(0, 47, 74, 0.368627451);
  height: 300px;
  width: 300px;
  border-radius: 300%;
  top: 55%;
  left: 5%;
  z-index: -1;
}
#cv-global #cv-container #Cv .circle3 {
  position: absolute;
  background-color: rgba(11, 155, 212, 0.438);
  height: 200px;
  width: 200px;
  border-radius: 300%;
  top: 90%;
  left: 43%;
  z-index: -1;
}
#cv-global #cv-container #Cv .circle4 {
  position: absolute;
  border: 5px solid rgba(11, 155, 212, 0.438);
  height: 700px;
  width: 700px;
  border-radius: 300%;
  top: 0%;
  left: 30%;
  z-index: -1;
}
#cv-global .image {
  height: 92vh;
  width: 35vw;
  -webkit-box-shadow: 0 0 2em black;
          box-shadow: 0 0 2em black;
  margin: auto;
  margin-top: 10vh;
}
#cv-global .image img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 92vh;
  width: 35vw;
}
@media screen and (max-width: 635px) {
  #cv-global .image img {
    width: 80vw;
    height: 70vh;
  }
}
@media screen and (max-width: 1035px) {
  #cv-global .image img {
    width: 65vw;
  }
}
@media screen and (max-width: 635px) {
  #cv-global .image {
    width: 80vw;
    height: 70vh;
  }
}
@media screen and (max-width: 1035px) {
  #cv-global .image {
    width: 65vw;
  }
}

#contact {
  overflow: hidden;
}
#contact .circle1 {
  position: absolute;
  border: 5px solid rgba(0, 47, 74, 0.368627451);
  height: 500px;
  width: 500px;
  border-radius: 300%;
  top: 0%;
  left: 85%;
  z-index: -1;
}
#contact .circle2 {
  position: absolute;
  background-color: rgba(11, 155, 212, 0.438);
  height: 300px;
  width: 300px;
  border-radius: 300%;
  top: 55%;
  left: 5%;
  z-index: -1;
}
#contact #head-contact {
  text-align: center;
  color: #002f4a;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-radius: 10px;
  height: 22vh;
  width: 50vw;
  margin: auto;
  padding: 2em;
  margin-top: 5vh;
  margin-bottom: 3vh;
  font-size: 1.7vh;
}
#contact #head-contact h2 {
  font-family: "font-title", sans-serif;
  margin-bottom: 2vh;
  font-size: 3vh;
  letter-spacing: 1px;
}
#contact #head-contact p {
  font-family: "font-text", sans-serif;
}
@media screen and (max-width: 1035px) {
  #contact #head-contact {
    width: 100vw;
  }
}
#contact #content-contact {
  background-color: #002f4a;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 10px;
  height: 48vh;
  width: 70vw;
  margin: auto;
  padding: 2em;
  font-size: 1.5vh;
  -webkit-box-shadow: 0 0 2em black;
          box-shadow: 0 0 2em black;
  font-size: 2.5vh;
}
#contact #content-contact #infos-contact {
  margin-top: 0;
  height: 27vh;
  width: 50%;
}
#contact #content-contact #infos-contact .navLi {
  text-decoration: none;
}
#contact #content-contact #infos-contact li {
  height: 9vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "font-text", sans-serif;
}
#contact #content-contact #infos-contact li svg {
  margin-right: 10px;
}
#contact #content-contact #infos-contact li path {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#contact #content-contact .form-content {
  width: 50%;
  height: 45vh;
  border-left: 1px solid #ebebeb;
}
#contact #content-contact .form-content div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-family: "font-text", sans-serif;
  margin-top: 2vh;
  margin-left: 1vw;
}
#contact #content-contact .form-content div input {
  border-radius: 10px;
  border: 1px solid rgba(0, 47, 74, 0.368627451);
  padding: 5px;
}
#contact #content-contact .form-content div textarea {
  border-radius: 10px;
  border: 1px solid rgba(0, 47, 74, 0.368627451);
  padding: 5px;
}
#contact #content-contact .form-content div label {
  margin-bottom: 1vh;
}
#contact #content-contact .form-content div #email {
  height: 3vh;
  width: 15vw;
}
@media screen and (max-width: 1035px) {
  #contact #content-contact .form-content div #email {
    width: 100%;
  }
}
#contact #content-contact .form-content div #mess {
  min-height: 20vh;
  max-height: 25vh;
  min-width: 25vw;
  max-width: 31vw;
}
@media screen and (max-width: 1035px) {
  #contact #content-contact .form-content div #mess {
    max-width: 100%;
  }
}
#contact #content-contact .form-content #button {
  margin: auto;
  margin-top: 2vh;
  width: 7vw;
  height: 4vh;
  background-color: rgba(11, 155, 212, 0.438);
  color: #ebebeb;
  border-radius: 25px;
  border: 1px solid rgb(11, 155, 212);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  font-family: "font-title", sans-serif;
  font-size: 1.5vh;
}
#contact #content-contact .form-content #button:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  cursor: pointer;
  background-color: rgb(11, 155, 212);
  -webkit-box-shadow: 0 0 1em black;
          box-shadow: 0 0 1em black;
}
@media screen and (max-width: 1035px) {
  #contact #content-contact .form-content #button {
    width: 60%;
  }
}
@media screen and (max-width: 1035px) {
  #contact #content-contact {
    width: 85vw;
    font-size: 1vh;
  }
}
#contact .formMessage {
  top: -50px;
}

#summary h1 {
  color: black;
  font-family: "font-title", sans-serif;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 5vh;
  font-size: 4vh;
}
#summary p {
  color: black;
  font-family: "font-text", sans-serif;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 5vh;
  font-size: 2vh;
}
#summary #sumContent {
  color: black;
  display: grid;
  grid-template-areas: "sass js" "react js" "redux js";
  text-align: center;
  margin-top: 5vh;
}
#summary #sumContent h2 {
  font-family: "font-title", sans-serif;
  margin-bottom: 2vh;
  text-decoration: underline;
  font-size: 3vh;
}
#summary #sumContent li {
  color: black;
  font-family: "font-text", sans-serif;
  font-size: 2vh;
}
#summary #sumContent li:hover {
  text-decoration: underline;
}
#summary #sumContent #SumSass {
  grid-area: sass;
}
#summary #sumContent #SumJs {
  grid-area: js;
  border-left: 1px solid black;
}
#summary #sumContent #SumReact {
  grid-area: react;
}
#summary #sumContent #SumRedux {
  grid-area: redux;
}/*# sourceMappingURL=index.css.map */