#home{
 
    overflow: hidden;
    height: 100vh;

        #content-home{
            margin: auto;
            height: 50vh;
            width: 60vw;
            position: relative;
            
            h3{
                font-family: $font-text;
                background-color: $lightblue;
                position: absolute;
                padding: 3%;
                padding-right: 6%;
                max-width: 35vw;
                font-size: 3vh;
                border-radius: 76% 24% 90% 10% / 20% 85% 15% 80%;
                @include childCenter;
                letter-spacing: 2px;
                box-shadow: 0 0 1em black;

                @media screen and (max-width: 1035px) {
                    max-width: 70vw;
                    font-size: 2.5vh;
                    padding-right: 3%;
                }
            }

            .photo_home{
                position: absolute;
                background-image: url("../img/me.png");
                height: 200px;
                width: 200px;
                margin-top: -13vh;
                margin-left: 6.5vw;
                border-top-left-radius: 50%;
                border-top-right-radius: 50%;
                background-size: contain;

                @media screen and (max-width: 1335px) {
                    display: none;
                }
            }

            
            
            h2{
                font-family: $font-title;
                background-color: $blue;
                position: absolute;
                padding: 2.5%;
                @include childCenter;
                z-index: 2;
                font-size: 2.5vh;
                max-height: 8vh;
                border-radius: 10px;
                box-shadow: 0 0 1em black;

                @media screen and (max-width: 1035px) {
                    margin-left: 5%;
                }
            }

            #first-text{
                padding: 3%;
                font-family: $font-text;
                background-color: $lightblue;
                position: absolute;
                max-width: 23vw;
                max-height: 25vh; 
                font-size: 1.6vh;
                z-index: 0;
                border-radius: 10px;
                box-shadow: 0 0 1em black;

                @media screen and (max-width: 1035px) {
                    max-width: 50vw;
                    font-size: 1vh;
                }

            }
            #second-text{
                padding: 3%;
                font-family: $font-text;
                background-color: $blue;
                position: absolute;
                max-width: 23vw;
                max-height: 35vh;
                font-size: 1.6vh;
                border-radius: 10px;
                box-shadow: 0 0 1em black;

                @media screen and (max-width: 1035px) {
                    max-width: 50vw;
                    font-size: 1vh;
                    margin-top: 5%;
                }

            }

            .circle1{
                position: absolute;
                border: 5px solid $blue2;
                height: 500px;
                width: 500px;
                border-radius: 300%;
                top: 70%;
                left: -20%;
                z-index: -1;
            }

            .circle2{
                position: absolute;
                background-color: $blue2;
                height: 300px;
                width: 300px;
                border-radius: 300%;
                top: -10%;
                left: 82%;
                z-index: -1;
            }

            .circle3{
                position: absolute;
                background-color: $lightblue2;
                height: 200px;
                width: 200px;
                border-radius: 300%;
                top: 10%;
                left: -43%;
                z-index: -1;
            }

            .circle4{
                position: absolute;
                border: 5px solid $lightblue2;
                height: 1000px;
                width: 1000px;
                border-radius: 300%;
                top: 70%;
                left: 100%;
                z-index: -1;
            }


            @media screen and (max-width: 1035px) {
                width: 100vw;
            }
        }

        footer{
            @include childCenter;
            background-color: $blue;
            width: 50vw;
            height: 6vh;
            border-top-right-radius: 15px;
            font-family: $font-text;
            font-size: 1.5vh;

            .navLi{
                margin: auto;
                text-decoration: none;
            }

            li{
                margin: auto;
                @include childCenter;
                transition: 0.3s;

                &:hover{
                    color: $lightblue;
                    cursor: pointer;
                }

                svg{
                text-align: center;
                margin-right: 6px;
                }
            }
            @media screen and (max-width: 1035px) {
                width: 100vw;
                border-top-right-radius: 0px;
                font-size: 0.7vh;
            }
        }
}