#navigation {
  width: 100vw;
  height: 20vh;
  display: flex;
  background-color: $blue;
  color: $white;
  font-family: $font-title;
  

  #navbar {
    display: flex;
    justify-content: flex-start;
    width: 40%;
    z-index: 1;
    
    #dropdown {
    position: absolute;
    background-color: $blue;
    color: $white;
    border-radius: 10px;
    box-shadow: 0 0 1em black;
    z-index: 0;
    width: 100%;
    top: 15vh;
    

    .navlink2 {
      width: 100%;
      height: auto;
      text-align: center;
      text-decoration: none;
      font-size: 1.5vh;
      margin-bottom: 1vh;
      margin-top: 1.5vh;
      transition: 0.3s;

      &:hover{
          transform: scale(1.2);
          font-weight: 900;
      }

        @media screen and (max-width: 1035px) {
          display: none;
      }
    }
  }

    .navlink {
      margin: 5px;
      width: 7vw;
      @include childCenter;
      font-size: 1vw;
      letter-spacing: 3px;
      position: relative;
      text-decoration: none;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        transform: scale(1.3);
        background-color: $lightblue;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        box-shadow: 0 0 2em black;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 2px;
        background-color: $white;
        top: calc(100% + -40%);
        display: block;
        transition: 0.3s;
      }

      &:hover::after {
        width: 68%;
      }

      @media screen and (max-width: 1035px) {
        font-size: 1vh;
        letter-spacing: 0;
        width: 10vw;
    }
    }

    @media screen and (max-width: 1035px) {
      width: 60%;
  }
  }

  #logo-container {
    width: 20%;
    @include childCenter;
    background-image: url("../img/logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (max-width: 1035px) {
      display: none;
  }
  }

  #reseauxNav {
    width: 40%;
    display: flex;
    justify-content: flex-end;

    li {
      width: 8vw;
      @include childCenter;

      @media screen and (max-width: 1035px) {
        margin: 5px;
    }

      svg {
        height: 3vh;
        transition: 0.3s;

        @media screen and (max-width: 1035px) {
          height: 2vh;
      }

        &:hover {
          transform: scale(1.3);
          cursor: pointer;
        }
      }
    }
  }

  .dropdown2 {
      position: absolute;
      background-color: $lightblue;
      color: $white;
      border-radius: 10px;
      box-shadow: 0 0 1em black;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 10vh;
      left: 6vw;
      top: 0;


        &::-webkit-scrollbar {
          width: 8px; 
          border-radius: 4px;
        }
    
        &::-webkit-scrollbar-thumb {
          background-color: $white; 
          border-radius: 4px; 
        }
    
        &::-webkit-scrollbar-track {
          background-color: $blue; 
          border-radius: 4px;
        }
      .navlink3 {
        width: 80%;
        text-align: center;
        text-decoration: none;
        font-size: 0.8vh;

        li{
          transition: 0.3s;
          margin-top: 1vh;
          margin-bottom: 2vh;
        }

        li:hover{
              background-color: red($color: #000000);
              transform: scale(1.1);
              font-weight: 900;
          }

          &:visited{
              color: $white;
          }
      }
    }
}