.project{
    position: relative;
    overflow: hidden;
    height: 100vh;

    .rightArrow{
        position: absolute;
        top: 53%;
        left: 90%;
        font-size: 5vh;
        color: $blue2;
        transition: 0.3s;

        &:hover{
            transform: scale(1.3);
            color: $blue;
        }
    }

    .leftArrow{
        position: absolute;
        top: 53%;
        left: 10%;
        font-size: 5vh;
        color: $blue2;
        transition: 0.3s;

        &:hover{
            transform: scale(1.3);
            color: $blue;
        }
    }

    .project-container{
        height: 50vh;
        width: 30vw;
        margin: auto;
        margin-top: 15vh;
        z-index: 0;
        border-radius: 20px;
        box-shadow: 0 0 1em black;
        transition: 1s;
        

        &:hover{
            cursor: pointer;
        }
        
        .circle1{
        position: absolute;
        border: 5px solid $blue2;
        height: 500px;
        width: 500px;
        border-radius: 300%;
        top: 10%;
        left: 50%;
        z-index: -1;
        }

        .circle2{
            position: absolute;
            background-color: $blue2;
            height: 300px;
            width: 300px;
            border-radius: 300%;
            top: 70%;
            left: 89%;
            z-index: -1;
        }

        .circle3{
            position: absolute;
            background-color: $lightblue2;
            height: 200px;
            width: 200px;
            border-radius: 300%;
            top: 10%;
            left: 3%;
            z-index: -1;
        }

        .project-title{
            background-color: $lightblue;
            height: 5vh;
            width: 20vw;
            position: absolute;
            top: 32%;
            left: 40%;
            border-radius: 20px;
            box-shadow: 0 0 1em black;
            overflow: hidden;

            .title-cache{
                height: 5vh;
                width: 10vw;
                background-color: $blue;
                position: absolute;
                z-index: 5;
                box-shadow: 0 0 3em black;
                transform: skew(20deg);
            }

            h1{
                @include childCenter;
                font-family: $font-title;
                margin-top: 5px;
                font-size: 3vh;

                @media screen and (max-width: 1035px) {
                    font-size: 1.5vh;
                }
            }

            @media screen and (max-width: 1035px) {
                width: 35vw;
                left: 33%;
                @include childCenter;
                padding-bottom: 1vh;
            }
        }

        .project-languages{
            background-color: $lightblue;
            height: 30vh;
            width: 15vw;
            position: absolute;
            border-radius: 20px;
            box-shadow: 0 0 1em black;
            @include childCenter;
            flex-direction: column;

            ul{
                li{
                    font-family: $font-text;
                    margin: 8px;
                    font-size: 3vh;

                    @media screen and (max-width: 1035px) {
                        font-size: 1.5vh;
                    }
                }
            }
            @media screen and (max-width: 1035px) {
                width: 25vw;
                margin-left: 35%;
                margin-top: 35%;
                height: 20vh;
            }
        }

        .project-description{
            @include childCenter;
            flex-direction: column;
            background-color: $blue;
            height: 40vh;
            width: 17vw;
            position: absolute;
            border-radius: 20px;
            box-shadow: 0 0 1em black;

            p{
                font-family: $font-text;
                margin: 8px;
                margin-left: 12px;
                font-size: 2vh;

                @media screen and (max-width: 1035px) {
                    font-size: 1vh;
                }
            }
            @media screen and (max-width: 1035px) {
                height: 20vh;
                width: 25vw;
            }
        }

        #code-box{
            display: none;
        }

        #site-box{
            display: none;
        }

        @media screen and (max-width: 1035px) {
            width: 65vw;
        }
    }

    .hoverProject{
        height: 50vh;
        width: 50vw;
        margin: auto;
        margin-top: 15vh;
        z-index: 0;
        border-radius: 20px;
        box-shadow: 0 0 2em black;
        transition: 1s ease;

        &:hover{
            cursor: pointer;
        }
    
            .project-title{
                display: none;
            }
    
            .project-languages{
                display: none;
            }
    
            .project-description{
                display: none;
            }
        
        #code-box{
            @include childCenter;
            background-color: $blue;
            width: 10vw;
            height: 8vh;
            border-radius: 10px;
            position: absolute;
            top: 40%;
            left: 35%;
            box-shadow: 0 0 1em black;
            font-family: $font-title;
            transition: 0.5s;
            font-size: 1.5vh;

            &:hover{
                transform: scale(1.2);
                background-color: $lightblue;
            }
            
            h2{
                margin-right: 0.5vw;

                &::before{
                    color: $white;
                }
            }

            @media screen and (max-width: 1035px) {
                width: 25vw;
                left: 20%;
                font-size: 1vh;
            }
        }

        #site-box{
            @include childCenter;
            background-color: $blue;
            width: 10vw;
            height: 8vh;
            border-radius: 10px;
            position: absolute;
            top: 40%;
            left: 55%;
            box-shadow: 0 0 1em black;
            font-family: $font-title;
            transition: 0.5s;
            font-size: 1.5vh;

            &:hover{
                transform: scale(1.2);
                background-color: $lightblue;
            }

            h2{
                margin-right: 0.5vw;
            }
            @media screen and (max-width: 1035px) {
                width: 25vw;
                font-size: 1vh;
            }
        }

        .circle1{
            position: absolute;
            border: 5px solid $blue2;
            height: 500px;
            width: 500px;
            border-radius: 300%;
            top: 10%;
            left: 50%;
            z-index: -1;
            }
    
            .circle2{
                position: absolute;
                background-color: $blue2;
                height: 300px;
                width: 300px;
                border-radius: 300%;
                top: 70%;
                left: 89%;
                z-index: -1;
            }
    
            .circle3{
                position: absolute;
                background-color: $lightblue2;
                height: 200px;
                width: 200px;
                border-radius: 300%;
                top: 10%;
                left: 3%;
                z-index: -1;
            }
            @media screen and (max-width: 1035px) {
                width: 80vw;
            }
    }

    #project1-pic{
        background-image: url("../img/Projet1.png");
        @include background;
    }

    #project2-pic{
        background-image: url("../img/Projet2.png");
        @include background;
    }

    #project3-pic{
        background-image: url("../img/Projet3.png");
        @include background;
    }

    #project4-pic{
        background-image: url("../img/Projet4.png");
        @include background;
    }

    #project5-pic{
        background-image: url("../img/Projet5.png");
        @include background;
    }

    #project6-pic{
        background-image: url("../img/Projet6.png");
        @include background;
    }

    #project7-pic{
        background-image: url("../img/Projet7.png");
        @include background;
    }

    #project8-pic{
        background-image: url("../img/Projet8.png");
        @include background;
    }

    #project9-pic{
        background-image: url("../img/Projet9.png");
        @include background;
    }

    #project10-pic{
        background-image: url("../img/Projet10.png");
        @include background;
    }

    #project11-pic{
        background-image: url("../img/Projet11.png");
        @include background;
    }

    #project12-pic{
        background-image: url("../img/Projet12.png");
        @include background;
    }

    #project13-pic{
        background-image: url("../img/Projet13.png");
        @include background;
    }

    #project14-pic{
        background-image: url("../img/Projet14.png");
        @include background;
    }

    #project15-pic{
        background-image: url("../img/Projet15.png");
        @include background;
    }

    #project16-pic{
        background-image: url("../img/Projet16.png");
        @include background;
    }

    #project17-pic{
        background-image: url("../img/Projet17.png");
        @include background;
    }

    #project18-pic{
        background-image: url("../img/Projet18.png");
        @include background;
    }

    #project19-pic{
        background-image: url("../img/Projet19.png");
        @include background;
    }

    #project20-pic{
        background-image: url("../img/Projet20.png");
        @include background;
    }

    #project21-pic{
        background-image: url("../img/Projet21.png");
        @include background;
    }

    #project22-pic{
        background-image: url("../img/Projet22.png");
        @include background;
    }

    #project23-pic{
        background-image: url("../img/Projet23.png");
        @include background;
    }

    #project24-pic{
        background-image: url("../img/Projet24.png");
        @include background;
    }

    #project25-pic{
        background-image: url("../img/Projet25.png");
        @include background;
    }

    #project26-pic{
        background-image: url("../img/Projet26.png");
        @include background;
    }

    #project27-pic{
        background-image: url("../img/Projet27.png");
        @include background;
    }

    #project28-pic{
        background-image: url("../img/Projet28.png");
        @include background;
    }

    #project29-pic{
        background-image: url("../img/Projet29.png");
        @include background;
    }

    #project30-pic{
        background-image: url("../img/Projet30.png");
        @include background;
    }

    #project31-pic{
        background-image: url("../img/Projet31.png");
        @include background;
    }
    
}