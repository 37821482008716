#popup{
    height: 25vh;
    width: 20vw;
    @include childCenter;
    flex-direction: column;
    text-align: center;
    position: absolute;
    border-radius: 76% 24% 90% 10% / 20% 85% 15% 80%;
    box-shadow: 0 0 1em black;
    background-color: $lightblue;
    position: fixed;
    top: 100%;
    
    h2{
        font-family: $font-title;
        letter-spacing: 1px;
        font-size: 2.5vh;
    }

    form{

        div {
        height: 5vh;
        display: flex;

            label {
            @include childCenter;
            width: 2vw;
            margin-right: 5px;
            font-family: $font-text;
            font-size: 1.5vh;
            }

            #email{
                margin: auto;
                height: 2vh;
                max-width: 8vw;
                margin-left: 10px;
            }

            #mess{
                width: 12vw;
                max-width: 12vw;
                height: 6vh;
                max-height: 6vh;
                margin-left: 10px;
            }
        }

        #button{
            width: 5vw;
            height: 3vh;
            background-color: $blue2;
            color: $white;
            border-radius: 25px;
            border: 1px solid $blue2;
            margin-top: 10%;
            transition: 0.3s;
            font-family: $font-title;
            font-size: 1.5vh;

            &:hover{
                transform: scale(1.3);
                cursor: pointer;
                background-color: $blue;
                box-shadow: 0 0 1em black;
            }
        }
    }

    .iconX{
        position: absolute;
        border: 2px solid $blue2;
        border-radius: 100%;
        width: 2vw;
        height: 4vh;
        padding: 5px;
        left: 77%;
        top: -5%;
        color: $blue2;
        background-color: $white;
        transition: 0.3s ease;

        &:hover{
            color: $blue;
            border-color: $blue;
            transform: scale(1.2);
            cursor: pointer;
            box-shadow: 0 0 1em black;
        }
    }
    @media screen and (max-width: 1035px) {
        display: none;
        right: 10000px;
    }
}