#notFound{
    text-decoration: none;
    background-color: $white;
    @include childCenter;
    flex-direction: column;
    height: 100vh;

    h1{
        font-size: 5vh;
        margin: 30px;
        color: $blue;
    }

    h3{
        color: $blue;
    }
}