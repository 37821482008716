@font-face {
    font-family: "font-title";
    src: url(../fonts/Montserrat-Light.ttf);
}

@font-face {
    font-family: "font-text";
    src: url(../fonts/Raleway-Regular.ttf);
}

$font-title: "font-title", sans-serif;
$font-text: "font-text", sans-serif;

$blue: #002f4a;
$blue2: #002f4a5e;
$lightblue: rgb(11, 155, 212);
$lightblue2: rgba(11, 155, 212, 0.438);
$white: #ebebeb;

*{
    margin: 0;
    padding: 0;
    list-style-type: none;
    box-sizing: border-box;
    
}

body{
    overflow-x: hidden;
    min-height: 100vh;
    color: $white;
    @media screen and (max-width: 1035px) {
        min-height: auto;
    }
}

.navlink{
    color: $white;
    text-decoration: none;
    
    &:visited{
        color: $white;
    }
}

.navlink2{
    &:visited{
        color: $white;
    }
}

li{
    color: $white;
}




@mixin childCenter{
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin background{
    background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
}



